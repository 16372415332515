import React from 'react'

function footer() {
  return (
      <>
      <footer className='container-fluid main-about'>
         <div className='row about-section'>
          <div className='col-3 about-box'>
              <h6><b>Star</b> Connect</h6>
              <p>Welcome to Start Connect, where we
                   redefine connectivity with innovative
                   smartphone solutions designed 
                   for the modern world.</p>
          </div>
          <div className='col-3'>
            <ul>
              <li><b>Links</b></li>
              <li>About</li>
              <li>Work</li>
              <li>Services</li>
              <li>Client</li>
            </ul>
          </div>
          <div className='col-3'>
            <ul>
              <li><b>Our Services</b></li>
              <li>Export/Import</li>
              <li>Renewed Mobile</li>
              <li>Distributorship </li>
            </ul>
          </div>
    
         </div>
         <div className='row'>
         <div className='col-12 p-0'>
             <div className='footer-copyright'>
             <p>@ 2024 Star connect , LLC. Pune Maharshtra. India.</p>
             </div>
          </div>
         </div>
      </footer>
      </>
  )
}

export default footer
