import React from 'react'
import img_about from '../assets/images/02.png'
import img_work from '../assets/images/03.png'
import services_img_1 from '../assets/images/04.png'
import services_img_2 from '../assets/images/05.png'
import services_img_3 from '../assets/images/06.png'
import our_ceo_1 from '../assets/images/07.png'
import our_ceo_2 from '../assets/images/08.png'
import clients_1 from '../assets/images/09.png'
import clients_2 from '../assets/images/10.png'
import clients_3 from '../assets/images/11.png'
import clients_4 from '../assets/images/12.png'
import clients_5 from '../assets/images/13.png'
import clients_6 from '../assets/images/14.png'
import clients_7 from '../assets/images/15.png'
import clients_8 from '../assets/images/16.png'
import clients_9 from '../assets/images/17.png'
import clients_10 from '../assets/images/18.png'
import clients_11 from '../assets/images/19.png'
import user_1 from '../assets/images/20.png'
import user_2 from '../assets/images/21.png'
import user_3 from '../assets/images/23.png'
import user_4 from '../assets/images/24.png'
import user_5 from '../assets/images/25.png'
import { IoStar } from "react-icons/io5";
import Slider from "react-slick";
import { FaArrowRightLong } from "react-icons/fa6";
import { FaArrowLeftLong } from "react-icons/fa6";

function main() {

  const NextArrow = (props) => {
    const { onClick } = props;
    return (
      <div className="slick-arrow next-arrow" onClick={onClick}>
        <FaArrowRightLong  className='icons'/>
      </div>
    );
  };
  
  const PrevArrow = (props) => {
    const { onClick } = props;
    return (
      <div className="slick-arrow prev-arrow" onClick={onClick}>
        <FaArrowLeftLong className='icons' />
      </div>
    );
  };

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    nextArrow: <NextArrow  />,
     prevArrow: <PrevArrow  />,
     autoplay: true,
     dots: false,
     responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          arrows : false,
        }
      },
    ]
  };

 

  return (
    <>
      <div className='container-fluid main-section'>
         <div className='row star-connect'>
          <div className='col-12 p-0'>
             <div className='star-connect-weraties'>
               <h6>Star Connect</h6>
               <p>Connecting Technologies </p>
             </div>
          </div>
         </div>
         <div className='row about-section' id='about'>
            <div className='col-12'>
                 <div className='about-title'>
                    <h6><p>Know Us More</p><b>ABOUT US</b></h6>
                     <div className='row'>
                          <div className='col-7 d-flex align-items-center'>
                            <div className='my-title'>
                      <h5><b>Star Connect</b> , Develop System that Works</h5>
                      <p>Welcome to Start Connect, where we redefine connectivity with innovative smartphone solutions designed for the modern world. Founded with a mission to deliver high-quality, feature-rich smartphones that bridge the gap between performance and affordability, Start Connect is committed to empowering users with devices that meet their needs.</p>
                      <p>We blend cutting-edge technology with sleek, user-friendly designs, making sure each device offers an immersive experience. Our team is passionate about continuous improvement, focusing on fast performance, advanced camera systems, and long-lasting battery life to keep you connected and engaged throughout the day.</p>
                        </div>
                          </div>
                        <div className='col-5 d-flex align-items-center'>
                        <div className='title-box'>
                            <img src={img_about} className='img-fluid' />
                        </div>
                        </div>
                     </div>
                 </div>  
            </div>
         </div>
         <div className='row work-section' id='work'>
            <div className='col-12'>
                 <div className='work-title'>
                    <h6><p>Know Us More</p><b>WORK WE HAVE DONE</b></h6>
                     <div className='row'>
                     <div className='col-5 d-flex align-items-center'>
                        <div className='work-box'>
                            <img src={img_work} className='img-fluid' />
                        </div>
                        </div>
                          <div className='col-7 d-flex align-items-center'>
                            <div className='my-title'>
                               <h5>Total <b className='ms-2'>25 years</b> & still learning new things</h5>
                               <p>We are having distributorship of so many top branded Companies in the field of  kitchen appliances, electronics & electricals,Crockery, cutlery & sanitaryware, </p>
                               <p>Like - Bajaj, Philips, Prestige, Maharaja Elite, kishco, Nirlep & so many.  Worked as a partner for more than 25 yrs For entire Maharashtra & Goa.</p>
                            </div>
                          </div>
                     </div>
                 </div>  
            </div>
         </div>
         <div className='row services-section' id='service'>
            <div className='col-12'>
                 <div className='services-title'>
                    <h6><p>What we Do?</p><b>SERVICES</b></h6>
                     <div className='row services'>
                     <div className='col-4 d-flex align-items-center'>
                        <div className='services-box'>
                             <div className='services-img'>
                             <img src={services_img_1} className='img-fluid' />
                             </div>
                             <h6>Export/Import of Spices</h6>
                             <p>Exporting and importing spices involves a series of steps and regulations to ensure compliance with international trade rules, quality standards, and customs regulations. Here’s a breakdown of the process, major challenges.</p>
                             {/* <button className='learn-more'>Learn More</button> */}
                        </div>
                        </div>
                        <div className='col-4 d-flex align-items-center'>
                        <div className='services-box'>
                           
                           <div className='services-img'>
                           <img src={services_img_2} className='img-fluid' />
                           </div>
                             <h6>Distribution</h6>
                             <p>We have experience in distributing brands like iPhone, Oppo, Micromax, as well as appliances like Hitachi ACs and Vu TVs, highlights your ability to navigate diverse markets and adapt to various product logistics.</p>
                             {/* <button className='learn-more'>Learn More</button> */}
                        </div>
                        </div>
                        <div className='col-4 d-flex align-items-center'>
                        <div className='services-box'>
                        <div className='services-img'>
                            <img src={services_img_3} className='img-fluid' />
                            </div>
                             <h6>Renewed Mobile</h6>
                             <p>At Start Connect, we believe that innovation doesn’t need to come at the expense of sustainability. Our mission is to provide high-quality, renewed smartphones that are both affordable and environmentally conscious.</p>
                             {/* <button className='learn-more'>Learn More</button> */}
                        </div>
                        </div>
                     </div>
                 </div>  
            </div>
         </div>
         <div className='row our-ceo-section'>
            <div className='col-12'>
                 <div className='our-ceo-title'>
                    <h6><p>Star Connect Founder</p><b>OUR CEO</b></h6>
                     <div className='row mr-sandeep'>
                          <div className='col-7 d-flex align-items-center'>
                            <div className='my-title'>
                          <h5>Mr. <b>Sandeep</b></h5>
                          <h6>Partner at Star Distributors / star connect</h6>
                          <p>Sandeep Rasiklal Shah has 37 years of business experience. He began as a partner at star connect, where he distributed brands like Bajaj and Philips across Maharashtra and Goa for 25 years.</p>
                          <p>He has also expanded into C&F services for Usha Shriram, worked as a national distributor for Acce Global, and launched an export business specializing in agricultural products and spices.</p>
                        </div>
                          </div>
                        <div className='col-5 d-flex align-items-center'>
                        <div className='our-ceo-box'> 
                            <img src={our_ceo_1} className='img-fluid' />
                        </div>
                        </div>
                     </div>
                     <div className='row'>
                     <div className='col-5 d-flex align-items-center'>
                        <div className='our-ceo-box'> 
                            <img src={our_ceo_2} className='img-fluid' />
                        </div>
                        </div>
                          <div className='col-7 d-flex align-items-center'>
                            <div className='my-title'>
                          <h5>Mr. <b>Nirav Shah</b></h5>
                          <h6>Partner at Star Distributors / star connect</h6>
                          <p>Nirav Shah has 20 years of business experience. He began as a partner at star connect, where he has been distributing brands like Bajaj and Philips across Maharashtra and Goa for two decades.</p>
                          <p>He has also expanded into C&F services for Usha Shriram, worked as a commission agent for Acce Global, and launched an export business specializing in agricultural products and spices.</p>
                        </div>
                          </div>
                     </div>
                 </div>  
            </div>
         </div>
         <div className='row our-clients-section'>
            <div className='col-12'>
                 <div className='our-clients-title'>
                    <h6><p>Our Clients</p><b>BRANDS WORKING WITH</b></h6>
                     <div className='row'>
                            <div className='col-12 our-clients'>
                              <div className='clients-box'>
                                    <img src={clients_1} className='img-fluid' />
                              </div>
                              <div className='clients-box'>
                                    <img src={clients_2} className='img-fluid' />
                              </div>
                              <div className='clients-box'>
                                    <img src={clients_3} className='img-fluid' />
                              </div>
                              <div className='clients-box'>
                                    <img src={clients_4} className='img-fluid' />
                              </div>
                              <div className='clients-box'>
                                    <img src={clients_5} className='img-fluid' />
                              </div>
                              <div className='clients-box'>
                                    <img src={clients_6} className='img-fluid' />
                              </div>
                              <div className='clients-box'>
                                    <img src={clients_7} className='img-fluid' />
                              </div>
                              <div className='clients-box'>
                                    <img src={clients_8} className='img-fluid' />
                              </div>
                              <div className='clients-box'>
                                    <img src={clients_9} className='img-fluid' />
                              </div>
                              <div className='clients-box'>
                                    <img src={clients_10} className='img-fluid' />
                              </div>
                              <div className='clients-box'>
                                    <img src={clients_11} className='img-fluid' />
                              </div>
                              <div className='clients-box'>
                                    <img src={user_4} className='img-fluid h-102' />
                              </div>
                              <div className='clients-box'>
                                    <img src={user_5} className='img-fluid ' />
                              </div>
                            </div>
                     </div>
                 </div>  
            </div>
         </div>  
         <div className='row income-brands-section'>
            <div className='col-12'>
                 <div className='income-brands-title'>
                    <h6><p>Income with Brands</p><b>REVENUE</b></h6>
                     <div className='row'>
                            <div className='col-12 income-brands'>
                              <div className='brands-box'>
                                    <h6>₹ 52,32,000</h6>
                                    <p>Oppo Mobile Company</p>
                              </div>
                              <div className='brands-box'>
                                    <h6>₹ 26,45,500</h6>
                                    <p>Hitachi Company</p>
                              </div>
                              <div className='brands-box'>
                                    <h6>₹ 17,58,650</h6>
                                    <p>Bajaj Company</p>
                              </div>
                              <div className='brands-box'>
                                    <h6>₹ 12,32,900</h6>
                                    <p>Kishco Company</p>
                              </div>
                            </div>
                     </div>
                 </div>  
            </div>
         </div> 
         <div className='row client-speak-section'>
            <div className='col-12'>
                 <div className='client-speak-title'>
                    <h6><b>TESTIMONIAL</b><p>Client Speak</p></h6>
                     <div className='row slider-box'>
                             <Slider {...settings}>
                                 <div>
                                    <div className='client-speak'>
                                  <div className='testimonial'>
                                     <img src={user_1} className='img-fluid' />
                                     <div>
                                        <h6>Subhash Gupta</h6>
                                        <p>Acce Global Group Manager</p>
                                     </div>
                                  </div>
                                  <p>"Start Connect has truly redefined what it means to deliver quality on a budget. I was initially skeptical, but the performance of their latest smartphone has genuinely impressed me. The interface is smooth, responsive, and feels as intuitive as high-end competitors. </p>
                                  <div className='star-icon'>
                                    <IoStar className='icons'/>
                                    <IoStar className='icons'/>
                                    <IoStar className='icons'/>
                                    <IoStar className='icons'/>
                                    <IoStar className='icons'/>
                                  </div>
                            </div>
                                 </div>
                                 <div>
                                    <div className='client-speak'>
                                  <div className='testimonial'>
                                     <img src={user_2} className='img-fluid' />
                                     <div>
                                        <h6>Vikas Mahote</h6>
                                        <p>Founder of Prabhav Soft</p>
                                     </div>
                                  </div>
                                  <p>"Start Connect has truly redefined what it means to deliver quality on a budget. I was initially skeptical, but the performance of their latest smartphone has genuinely impressed me. The interface is smooth and responsive."</p>
                                  <div className='star-icon'>
                                    <IoStar className='icons'/>
                                    <IoStar className='icons'/>
                                    <IoStar className='icons'/>
                                    <IoStar className='icons'/>
                                    <IoStar className='icons'/>
                                  </div>
                            </div>
                                 </div>
                                 <div>
                                    <div className='client-speak'>
                                  <div className='testimonial'>
                                     <img src={user_3} className='img-fluid' />
                                     <div>
                                        <h6>Dhrumil Dodiya</h6>
                                        <p>Apna shop owner</p>
                                     </div>
                                  </div>
                                  <p>"Start Connect has transformed the meaning of value in the smartphone market. At first, I wasn’t sure if it could deliver on its promises, but after using their latest model, I’m genuinely impressed. "</p>
                                  <div className='star-icon'>
                                    <IoStar className='icons'/>
                                    <IoStar className='icons'/>
                                    <IoStar className='icons'/>
                                    <IoStar className='icons'/>
                                    <IoStar className='icons'/>
                                  </div>
                            </div>
                                 </div>
                             </Slider>
                     </div>
                 </div>  
            </div>
         </div>  
         <div className='row get-touch-section' id='contact'>
            <div className='col-12'>
                 <div className='get-touch-title'>
                    <h6><b>GET IN TOUCH</b></h6>
                     <div className='row get-touch'>
                             <div className='col-6'>
                                 <h6>Send us a Note</h6>
                                 <div className='d-flex gap-2'>
                                  <input type='text' name='name' placeholder='Name' className='w-100' />
                                  <input type='email' name='email' placeholder='Email' className='w-100' />
                                 </div>
                                 <div>
                                  <textarea class="" name="" placeholder='Tell us more about your needs' rows="8"></textarea>
                                 </div>
                                 <div className='d-flex'>
                                    <button>Send Message</button>
                                 </div>
                             </div>
                             <div className='col-6'>
                             <iframe
                                 src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7365.740869224672!2d88.464762!3d22.621313!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f89fa843f35141%3A0xf200e4d62cb7d089!2sACCE%20GLOBAL%20SOFTWARE!5e0!3m2!1sen!2sin!4v1731578764575!5m2!1sen!2sin"
                                 width="100%"
                                 height="450"
                                 style={{ border: 0 }}
                                 allowFullScreen=""
                                 loading="lazy"
                                 referrerPolicy="no-referrer-when-downgrade"
                                 title="Google Map Embed"
                               ></iframe>
                             </div>
                     </div>
                 </div>  
            </div>
         </div>  
      </div>
    </>
  )
}

export default main
