import './App.css';
import Main from "./components/main";
import { createBrowserRouter , RouterProvider } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';

function App() {

  const router = createBrowserRouter([
    {
      path: "/" ,  
      element: <><Navbar/><Main /><Footer/></>
    },
  ])
  return (
    <div className="App home-container">
        <RouterProvider router={router} /> 
    </div>
  );
}

export default App;
