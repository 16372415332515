import React, { useState, useEffect } from 'react';
import '../assets/scss/style.css';
import { NavLink } from 'react-router-dom';
import { CgMenuRight, CgClose } from "react-icons/cg";

const Navbar = () => {
  const [scrolled, setScrolled] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 50);
    };
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <>
      <nav className={`main-navbar ${scrolled ? 'scrolled' : ''}`}>
        <p className="logo-img">Star Connect</p>

        {/* Toggle button for mobile view */}
        <button className="menu-icon" onClick={toggleMenu}>
          {menuOpen ? <CgClose size={30} /> : <CgMenuRight size={30} />}
        </button>

        <ul className={`nav-list ${menuOpen ? 'open' : ''}`}>
          <li><a href='#Home' className={(e) => e.isActive ? "active" : ""} to="/">Home</a></li>
          <li><a href='#about' className={(e) => e.isActive ? "active" : ""} to="/about">About us</a></li>
          <li><a href='#work' className={(e) => e.isActive ? "active" : ""} to="/work">Work</a></li>
          <li><a href='#service' className={(e) => e.isActive ? "active" : ""} to="/service">Service</a></li>
          <li><a href='#contact' className={(e) => e.isActive ? "active" : ""} to="/contact">Contact us</a></li>
          {/* <li><a href='#blog' className={(e) => e.isActive ? "active" : ""} to="/blog">Blog</a></li> */}
        </ul>

        <button className="btn-get-connect">Get Connect</button>
      </nav>
    </>
  );
};

export default Navbar;
